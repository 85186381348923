import gql from "graphql-tag";

export const Employees = {
    Queries: {
        Employees: gql`
        query employees {
            employees {
                Id
                Vorname
                Name
                Email
                DistributionId
            }
        }`,
        Employee: gql`
        query employee($id: ID, $distributionId: ID) {
            employee(id: $id, distributionId: $distributionId) {
                Id
                Vorname
                Name
                Email
                DistributionId
            }
        }`,
        ContactPerson: gql`
        query contactPerson($hostId: ID) {
            contactPerson(hostId: $hostId) {
                Id
                Vorname
                Name
                Email
                DistributionId
            }
        }`,
        EmployeeByUserId: gql`
        query employeeByUserId($userId: ID) {
            employeeByUserId(userId: $userId) {
                Id
                Vorname
                Name
                Email
                DistributionId
            }
        }`,
        EmployeesByActiveClientAdvisors: gql`
        query employeesByActiveClientAdvisors {
            employeesByActiveClientAdvisors {
                Id
                Vorname
                Name
                Email
                DistributionId
            }
        }`,
    },
    Mutations: {
        SaveContactPerson: gql`
        mutation saveContactPerson($hostId: ID, $employeeId: ID) {
            saveContactPerson(hostId: $hostId, employeeId: $employeeId) {
                IsSuccess
                Message
            }
        }`,
    }
}
