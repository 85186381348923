<template lang="pug">
.host-status-icon
  font-awesome-icon(:icon="['fas', 'question']" v-if="hostStatusId == HostStatusEnum.PotentialHost")
  font-awesome-icon.green(:icon="['fas', 'circle']" v-else-if="hostStatusId == HostStatusEnum.Active")
  font-awesome-icon.red(:icon="['fas', 'circle']" v-else-if="hostStatusId == HostStatusEnum.Inactive")
  font-awesome-icon(:icon="['fas', 'ban']" v-else-if="hostStatusId == HostStatusEnum.NotInterested")
  font-awesome-icon(:icon="['fas', 'face-grin-stars']" v-else-if="hostStatusId == HostStatusEnum.Interested")
</template>

<script>
import {HostStatusEnum} from "@/utils/enums/hostStatus/HostStatusEnum.ts";

export default {
  name: "HostStatusIcon",
  props: {
    hostStatusId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      HostStatusEnum: HostStatusEnum,
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}
.green {
  color: green;
}
.gray {
  color: gray;
}
</style>
