<template lang="pug">
  #app
    td-loading-overlay(v-if="loading")
    Header(
      v-if="selectedUser"
      :user="selectedUser"
      :users="users"
      :sales-support-user="salesSupportUser"
      :mailbox="mailbox"
      :change-user="changeUser"
    )
    .container-fluid
      router-view(:key="$route.fullPath")

</template>

<script>
import Header from "./views/header/Header";
import EventBus from "./event-bus";
import {EmployeesComponent} from "@/lib/components/employees/EmployeesComponent.ts";
import TdLoadingOverlay from "@/views/partials/loading/td-loading-overlay.vue";
import {MailboxesComponent} from "@/lib/components/mailboxes/MailboxesComponent.ts";
import {UsersComponent} from "@/lib/components/users/UsersComponent.ts";

export default {
  name: "App",
  components: {TdLoadingOverlay, Header},
  data() {
    return {
      user: undefined,
      selectedUser: undefined,
      employee: undefined,
      salesSupportUser: undefined,
      mailbox: undefined,
      loading: false,
    };
  },
  mounted() {
    try {
      this.loadUser();
      this.loadSalesSupportUser();
      EventBus.$on(
          "changeLoadingState",
          function (loading) {
            this.loading = loading;
          }.bind(this)
      );
      EventBus.$on(
          "changeLoginState",
          function () {
            this.loadUser();
            this.loadSalesSupportUser();
          }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadUser() {
      try {
        if (!this.$session.exists()) {
          this.user = undefined;
          this.selectedUser = undefined;
          return;
        }
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
            this.$apollo,
            this.$session.get("loggedInUserId")
        );
        this.user = user;
        if (!this.selectedUser) {
          this.selectedUser = user;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadSalesSupportUser() {
      try {
        if (!this.$session.exists()) {
          return this.salesSupportUser = undefined;
        }
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUserByEmail(
            this.$apollo,
            "sales-support@touridat.de"
        );
        this.salesSupportUser = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadEmployee() {
      try {
        const employeesComponent = new EmployeesComponent();
        const employee = await employeesComponent.getEmployeeByUserId(this.$apollo, this.selectedUser?.Id);
        this.employee = employee;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadMailbox() {
      try {
        const mailboxesComponent = new MailboxesComponent();
        const mailbox = await mailboxesComponent.getMailboxByUserId(
            this.$apollo,
            this.selectedUser?.Id
        );
        this.mailbox = mailbox;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async changeUser(userId) {
      try {
        const selectedUser = this.users.find((user) => user.Id == userId);
        if (!selectedUser) {
          return;
        }
        this.selectedUser = selectedUser;
        this.$session.set("userId", selectedUser.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    employee() {
      try {
        this.$session.set("employeeId", this.employee?.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    mailbox() {
      try {
        this.$session.set("mailboxId", this.mailbox?.Id);
        EventBus.$emit("changeMailbox", this.mailbox?.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    selectedUser() {
      try {
        if (!this.selectedUser) {
          return;
        }
        this.loadMailbox();
        this.loadEmployee();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    users() {
      try {
        if (!this.user && !this.salesSupportUser) {
          return undefined;
        }
        const users = [];
        if (this.user) {
          users.push(this.user);
        }
        if (this.salesSupportUser) {
          users.push(this.salesSupportUser);
        }
        return users;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  }
};
</script>
<style lang="scss">
@import "assets/styles/base";
</style>
