<template lang="pug">
.client-advisor-change-requests-table
  .table-responsive
    table.table.table-bordered
      thead
        tr
          td(v-if="showHost") Gastgeber
          th Benutzer
          th Status
          th erstellt am
          th erstellt von
          th aktualisiert am
          th aktualisiert von
          th(v-if="isEdit")
      tbody
        client-advisor-change-requests-table-row(
          v-for="clientAdvisorChangeRequest in clientAdvisorChangeRequests"
          :client-advisor-change-request="clientAdvisorChangeRequest"
          :show-host="showHost"
          :is-edit="isEdit"
        )


</template>

<script>
import ClientAdvisorChangeRequestsTableRow
  from "@/views/clientAdvisorChangeRequests/ClientAdvisorChangeRequestsTableRow.vue";

export default {
  name: 'ClientAdvisorChangeRequestsTable',
  components: {ClientAdvisorChangeRequestsTableRow},
  props: {
    clientAdvisorChangeRequests: {
      type: Array,
      required: true,
    },
    showHost: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
