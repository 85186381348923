<template lang="pug">
  .product-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'circle']" :class="{ 'text-success': product.IsVisibleInItemListing, 'text-danger': !product.IsVisibleInItemListing }")
    font-awesome-icon.mr-2(:icon="['fas', 'bed']")
    span {{ product.Id }}

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'person']")
    span {{ product.Personen }} Person(en)

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'moon']")
    span {{ product.Uebernachtungen }} Nächte

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'euro']")
    span {{ product.StartPreisFestPreis | formatEuro }} / {{ product.DealPreisLiveShopping | formatEuro }}

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'arrow-trend-up']")
    span {{ stock ? (stock.Available) : 0 }} / {{ dealStock ? (dealStock.Available) : 0 }}

</template>

<script>
import {StockComponent} from "@/lib/components/stock/StockComponent.ts";
import {StockTypesEnum} from "@/utils/enums/stockTypes/StockTypesEnum.ts";
import {PlatformsEnum} from "@/utils/enums/platforms/PlatformsEnum.ts";

export default {
  name: "ProductSearchSelectListResultsListItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stock: undefined,
      dealStock: undefined,
    };
  },
  async mounted() {
    try {
      this.loadStock();
      this.loadDealStock();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadStock() {
      try {
        this.stock = await this.getStock(StockTypesEnum.Shop);
      } catch (e) {
        console.error(e);
      }
    },
    async loadDealStock() {
      try {
        this.dealStock = await this.getStock(StockTypesEnum.Deal);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async getStock(stockTypeId) {
      try {
        const stockComponent = new StockComponent(undefined, "cache-first");
        const stock = await stockComponent.getStock(this.$apollo, stockTypeId, this.product.Id, PlatformsEnum.Shop);
        return stock;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  watch: {
    "product.Id"() {
      try {
        this.loadStock();
        this.loadDealStock();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  }
};
</script>

<style scoped></style>
