// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";
// @ts-ignore
import {ClientAdvisorChangeRequests} from "../../../graphql/clientAdvisorChangeRequests/ClientAdvisorChangeRequests.ts";

export class ClientAdvisorChangeRequestsComponent extends BaseComponent {
    async getClientAdvisorChangeRequests(apollo, hostId, userId, clientAdvisorChangeRequestStatusIds, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId && !userId && (clientAdvisorChangeRequestStatusIds && !Array.isArray(clientAdvisorChangeRequestStatusIds)  && offset == undefined  && !limit)) {
                return undefined;
            }
            const clientAdvisorChangeRequests = await apollo.query({
                query: ClientAdvisorChangeRequests.Queries.ClientAdvisorChangeRequests,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    hostId: hostId,
                    userId: userId,
                    clientAdvisorChangeRequestStatusIds: clientAdvisorChangeRequestStatusIds,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.clientAdvisorChangeRequests)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return clientAdvisorChangeRequests;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getClientAdvisorChangeRequestsCount(apollo, hostId, userId, clientAdvisorChangeRequestStatusIds) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId && !userId && (clientAdvisorChangeRequestStatusIds && !Array.isArray(clientAdvisorChangeRequestStatusIds))) {
                return undefined;
            }
            const clientAdvisorChangeRequestsCount = await apollo.query({
                query: ClientAdvisorChangeRequests.Queries.ClientAdvisorChangeRequestsCount,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    hostId: hostId,
                    userId: userId,
                    clientAdvisorChangeRequestStatusIds: clientAdvisorChangeRequestStatusIds,
                },
            })
                .then(({data}) => data?.clientAdvisorChangeRequestsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return clientAdvisorChangeRequestsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveClientAdvisorChangeRequest(apollo, clientAdvisorChangeRequest) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!clientAdvisorChangeRequest) {
                return {
                    IsSuccess: false,
                    Message: "Keine Anfrage gefunden.",
                };
            }
            const savedClientAdvisorChangeRequest = await apollo.mutate({
                mutation: ClientAdvisorChangeRequests.Mutations.SaveClientAdvisorChangeRequest,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    clientAdvisorChangeRequest: clientAdvisorChangeRequest,
                },
            })
                .then(({data}) => data?.saveClientAdvisorChangeRequest)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return savedClientAdvisorChangeRequest;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
