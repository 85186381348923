const moment = require("moment");

class SessionsComponent {
    handleQueryState(env) {
        try {
            if (!env) {
                return undefined;
            }
            let query = env.$route?.query;
            if (!query || !Object.keys(query).length) {
                let views = window.sessionStorage.getItem("views");
                if (!views) {
                    return;
                }
                if (!Array.isArray(views)) {
                    views = JSON.parse(views);
                }
                const view = views.find((v) => v.name === env.$route.name);
                if (!view) {
                    return;
                }
                query = view.query;
            }
            this._applyLocalDataAssingments(env, query);
            this.applyQueryState(env, query);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    saveQueryState(env, query) {
        try {
            if (!env || !query) {
                return;
            }
            let views = window.sessionStorage.getItem("views");
            if (!views) {
                views = [];
            }
            if (!Array.isArray(views)) {
                views = JSON.parse(views);
            }
            let view = views.find((view) => view.name === env.$route.name);
            if (!view) {
                view = {
                    name: env.$route.name,
                    query: {},
                };
            }
            view.query = query;
            const _view = views.find((v) => v.name === env.$route.name);
            if (!_view) {
                views.push(view);
            } else {
                views[views.indexOf(_view)] = view;
            }
            window.sessionStorage.setItem("views", JSON.stringify(views));
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    applyQueryState(env, query) {
        try {
            query = this.getQuery(query);
            env.$router
                .replace({...env.$router.currentRoute, query: query})
                .catch(() => {
                });
        } catch (e) {
            console.error(e);
        }
    }

    changeQueryState(env, query) {
        try {
            this.saveQueryState(env, query);
            const _query = this._getQuery(query);
            env.$router
                .push({
                    name: env.$route.name,
                    query: _query,
                })
                .catch(() => {
                });
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    getQuery(query) {
        try {
            return this._getQuery(query);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    _applyLocalDataAssingments(env, query) {
        try {
            if (!env || !query) {
                return;
            }
            for (const key of Object.keys(query)) {
                let value = query[key];
                const type = typeof value;
                if (type == "string") {
                    if (!value) {
                        env["_data"][key] = value;
                        continue;
                    }
                    if ((key.includes("offset") || key.includes("limit")) && this._isNumber(value)) {
                        value = parseInt(value);
                    } else if (
                        (value.includes("[") ||
                            value.includes("]") ||
                            value.includes("{") ||
                            value.includes("}")) &&
                        this._isParsable(value)
                    ) {
                        value = JSON.parse(value);
                    } else if (value === "true" || value === "false") {
                        value = value === "true";
                    }
                }
                env["_data"][key] = value;
            }
        } catch (e) {
            console.error(e);
        }
    }

    _getQuery(query) {
        try {
            const result = {};
            for (const key of Object.keys(query)) {
                let value = query[key];
                const type = typeof query[key];
                if (this._isDate(value)) {
                    value = moment(value).toISOString();
                } else if (type == "object") {
                    value = JSON.stringify(value);
                }
                result[key] = value;
            }
            return result;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    _isParsable(val) {
        try {
            let result = JSON.parse(val);
            result = !!result;
            return result;
        } catch (e) {
            return false;
        }
    }

    _isNumeric(value) {
        try {
            return /^-?\d+$/.test(value);
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    _isNumber(val) {
        try {
            if (val == undefined) {
                return false;
            }
            const isNumeric = this._isNumeric(val);
            if (!isNumeric) {
                return false;
            }
            val = parseInt(val);
            const isNumber = !isNaN(val);
            if (!isNumber) {
                return false;
            }
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    _isDate(val) {
        try {
            return Object.prototype.toString.call(val) === '[object Date]';
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}

module.exports = SessionsComponent;
