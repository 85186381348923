import gql from 'graphql-tag';

export const HostAcquisitionStatus = {
    Queries: {
        HostAcquisitionStatus: gql`
        query hostAcquisitionStatus {
            hostAcquisitionStatus {
                Id
                Name
            }
        }`,
        HostAcquisitionStatusById: gql`
        query hostAcquisitionStatusById($id: ID) {
            hostAcquisitionStatusById(id: $id) {
                Id
                Name
            }
        }`,
    },
};
