// @ts-ignore
import {Employees} from "../../../graphql/employees/Employees.ts";

export class EmployeesComponent {
  async getEmployee(apollo, id, distributionId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!id && !distributionId) {
        return undefined;
      }
      const employee = await apollo
          .query({
            query: Employees.Queries.Employee,
            variables: {
              id: id,
              distributionId: distributionId,
            },
          })
          .then(({ data }) => data?.employee)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!employee) {
        return undefined;
      }
      return employee;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getEmployeeByUserId(apollo, userId) {
    try {
      if (!apollo || !userId) {
        return undefined;
      }
      const employee = await apollo
        .query({
          query: Employees.Queries.EmployeeByUserId,
          variables: {
            userId: userId,
          },
        })
        .then(({ data }) => data?.employeeByUserId)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!employee) {
        return undefined;
      }
      return employee;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getEmployeesByActiveClientAdvisors(apollo) {
    try {
      const employees = await apollo.query({
        query: Employees.Queries.EmployeesByActiveClientAdvisors,
      })
          .then(({data}) => data?.employeesByActiveClientAdvisors)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!employees) {
        return undefined;
      }
      return employees;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
