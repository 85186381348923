// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";
// @ts-ignore
import {HostAcquisitionEntries} from "../../../graphql/hostAcquisitionEntries/HostAcquisitionEntries.ts";

export class HostAcquisitionEntriesComponent extends BaseComponent {
    async createHostAcquisitionEntry(apollo, hostAcquisitionEntry) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!hostAcquisitionEntry) {
                return {
                    IsSuccess: false,
                    Message: "Kein Akquise-Eintrag gefunden.",
                };
            }
            const createdHostAcquisitionEntry = await apollo.mutate({
                mutation: HostAcquisitionEntries.Mutations.CreateHostAcquisitionEntry,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    hostAcquisitionEntry: hostAcquisitionEntry,
                },
            })
                .then(({data}) => data?.createHostAcquisitionEntry)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return createdHostAcquisitionEntry;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getHostAcquisitionEntries(apollo, hostId = undefined, createdBy = undefined, from = undefined, to = undefined, areNullableDatesIncluded = undefined, hostAcquisitionStatusIds = undefined, isDone = undefined, offset = undefined, limit = undefined, order = [["Date", "ASC"], ["Id", "DESC"]]) {
        try {
            const hostAcquisitionEntries = await apollo.query({
                query: HostAcquisitionEntries.Queries.HostAcquisitionEntries,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    hostId: hostId,
                    createdBy: createdBy,
                    from: from,
                    to: to,
                    areNullableDatesIncluded: areNullableDatesIncluded,
                    hostAcquisitionStatusIds: hostAcquisitionStatusIds,
                    isDone: isDone,
                    offset: offset,
                    limit: limit,
                    order: order,
                }
            })
                .then(({data}) => data?.hostAcquisitionEntries)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                })
            return hostAcquisitionEntries;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
