import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSession from "vue-session";
import { vuePluginsUtils } from "./lib/utils/vue-plugins.utils";
import { InMemoryCache } from "apollo-cache-inmemory";
import Utils from "@/utils";
import {
  ModelSelect,
  ListSelect,
  MultiSelect,
  ModelListSelect,
} from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import VModal from "vue-js-modal";
import Sortable from "vue-sortable";
import moment from "moment";

Vue.component("multi-select", MultiSelect);
Vue.component("model-select", ModelSelect);
Vue.component("model-list-select", ModelListSelect);
Vue.component("list-select", ListSelect);

Vue.config.productionTip = false;

Vue.filter(
  Utils.vueFilter.formatInputDateFilter.name,
  Utils.vueFilter.formatInputDateFilter.func
);
Vue.filter(
  Utils.vueFilter.formatInputDateTimeFilter.name,
  Utils.vueFilter.formatInputDateTimeFilter.func
);
Vue.filter(
  Utils.vueFilter.formatDateFilter.name,
  Utils.vueFilter.formatDateFilter.func
);
Vue.filter(
    Utils.vueFilter.formatDateWithDayFilter.name,
    Utils.vueFilter.formatDateWithDayFilter.func
);
Vue.filter(
  Utils.vueFilter.formatValueFilter.name,
  Utils.vueFilter.formatValueFilter.func
);
Vue.filter(
  Utils.vueFilter.formatEuroFilter.name,
  Utils.vueFilter.formatEuroFilter.func
);
Vue.filter(
  Utils.vueFilter.formatPercentageFilter.name,
  Utils.vueFilter.formatPercentageFilter.func
);
Vue.filter(
  Utils.vueFilter.formatDateTimeFilter.name,
  Utils.vueFilter.formatDateTimeFilter.func
);
Vue.filter(
  Utils.vueFilter.formatCDNFilter.name,
  Utils.vueFilter.formatCDNFilter.func
);

Vue.use(VueSession, {
  persist: true,
});

Vue.use(VModal, {
  dynamicDefaults: {
    draggable: false,
    resizable: true,
    height: "auto",
  },
});
Vue.use(Sortable);
for (const util of vuePluginsUtils) {
  Vue.use(util.plugin, util.config);
}

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCircle,
  faTimes,
  faTools,
  faUser,
  faClone,
  faPlus,
  faMinus,
  faTrash,
  faFilePdf,
  faDoorOpen,
  faSave,
  fas,
  faAt,
  faGift,
  faPrint,
  faBan,
  faMoneyBill,
  faK,
  faReceipt,
  faRotate,
  faMagnifyingGlass,
  faUpRightFromSquare,
  faWarehouse,
  faTruckFast,
  faXmark,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileLines,
  faFloppyDisk,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAmazonPay,
  faPaypal,
  faEbay,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCircle,
  faTimes,
  faTools,
  faUser,
  faClone,
  faPlus,
  faTrash,
  faFilePdf,
  faDoorOpen,
  faSave,
  fas,
  faAt,
  faGift,
  faPrint,
  faBan,
  faAmazonPay,
  faPaypal,
  faMoneyBill,
  faK,
  faEbay,
  faReceipt,
  faRotate,
  faMagnifyingGlass,
  faUpRightFromSquare,
  faFileLines,
  faFloppyDisk,
  faTrashCan,
  faMinus,
  faPenToSquare,
  faWarehouse,
  faTruckFast,
  faXmark,
  faCalculator,
  faWhatsapp
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

Vue.prototype.$moment = moment;

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import config from "@/config/config";
import { createHttpLink } from "apollo-link-http";

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: config().graphQl.uri,
});

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
});

import ApolloClient from "apollo-client";

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions: {
    fetchPolicy: "no-cache",
  },
});

import VueApollo from "vue-apollo";

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.use(VueApollo);

new Vue({
  router,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
