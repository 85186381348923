<template lang="pug">
  .search-select(:class="{ 'search-select-disabled': isDisabled }")
    ul.search-select-list(@click="open")
      search-select-list-item(
        v-if="selectedData && !Array.isArray(selectedData)"
        :obj="selectedData"
        :type="selectedData.type"
        :deselect="deselect"
        :is-badge="true"
        :is-disabled="isDisabled"
      )
      search-select-list-item(
        v-else-if="selectedData && Array.isArray(selectedData)"
        v-for="obj in selectedData"
        :obj="obj"
        :type="obj.type"
        :deselect="deselect"
        :is-badge="true"
      )
      li.search-select-list-item(v-if="Array.isArray(selectedData) || !Array.isArray(selectedData) && !selectedData")
        .search-select-list-loading-item(v-if="isLoading")
          font-awesome-icon.mr-2(:icon="['fas', 'spinner']" :class="{ 'ml-2': isDataFilled }" size="lg")
          span {{ filter }}
        .search-select-list-input-item(v-else)
          input(type="text" v-model="filter" v-on:keyup.space="keyup" :placeholder="localPlaceholder" :disabled="isDisabled")

    ul.search-select-list-results(v-if="data && Array.isArray(data) && data.length" :class="{ 'd-block': isExpanded == true, 'd-none': isExpanded == false }")
      search-select-list-results-list-item(
        v-for="obj in data"
        :obj="obj"
        :type="obj.type"
        :field="obj.field"
        :select="select"
      )

</template>

<script>
import SearchSelectListItem from "@/views/selects/searchSelect/listItems/SearchSelectListItem.vue";
import SearchSelectListResultsListItem
  from "@/views/selects/searchSelect/resultsListItems/SearchSelectListResultsListItem.vue";
import EventBus from "@/event-bus";

export default {
  name: "SearchSelect",
  components: {SearchSelectListResultsListItem, SearchSelectListItem},
  props: {
    data: {
      type: [Array, Object],
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: undefined,
    },
    loadData: {
      type: Function,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    selectedData: {
      type: [Array, Object],
      required: false,
    },
    validData: {
      type: Function,
      required: false,
    },
    loadTimeout: {
      type: Number,
      required: false,
      default: 1000,
    },
    selectedDataChanged: {
      type: Function,
      required: false,
    },
    isSpaceAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      filter: "",
      timeout: undefined,
      isLoading: false,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
          "updateSearchSelect",
          function (field, filter) {
            if (this.field != field) {
              return;
            }
            if (filter) {
              this.filter = filter;
            }
            this.triggerLoadData();
          }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    open() {
      try {
        if (!this.isDataFilled || this.isDisabled) {
          return;
        }
        this.isExpanded = !this.isExpanded;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    keyup() {
      try {
        if (!this.isSpaceAdd) {
          return;
        }
        if (this.validData) {
          const isDataValid = this.validData(this.filter);
          if (!isDataValid) {
            this.filter = "";
            return;
          }
        }
        if (Array.isArray(this.data)) {
          this.selectedDataChanged(this.field, [
            ...(this.selectedData ? this.selectedData : []),
            ...[this.filter],
          ]);
        } else {
          this.selectedDataChanged(this.field, this.data);
        }
        this.filter = "";
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    select(data) {
      try {
        if (Array.isArray(this.selectedData)) {
          this.selectedDataChanged(this.field, [
            ...(this.selectedData ? this.selectedData : []),
            ...[data],
          ]);
        } else {
          this.selectedDataChanged(this.field, data);
        }
        this.isExpanded = false;
        this.filter = "";
      } catch (e) {
        console.error(e);
      }
    },
    deselect(data) {
      try {
        if (Array.isArray(this.selectedData)) {
          if (typeof data == "object") {
            const _selectedData = JSON.parse(JSON.stringify(this.selectedData));
            if (!_selectedData) {
              return;
            }
            _selectedData.splice(
                _selectedData.indexOf((d) => d.Id == data.Id),
                1
            );
            this.selectedDataChanged(this.field, _selectedData);
          } else {
            const _selectedData = JSON.parse(JSON.stringify(this.selectedData));
            if (!_selectedData) {
              return;
            }
            _selectedData.splice(_selectedData.indexOf(data), 1);
            this.selectedDataChanged(this.field, _selectedData);
          }
        } else {
          this.selectedDataChanged(this.field, undefined);
        }
        this.isExpanded = false;
      } catch (e) {
        console.error(e);
      }
    },
    async triggerLoadData() {
      try {
        this.isLoading = true;
        await this.loadData(this.filter);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    filter() {
      try {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = undefined;
        }
        this.timeout = setTimeout(this.triggerLoadData, this.loadTimeout);
      } catch (e) {
        console.error(e);
      }
    },
    data() {
      try {
        this.isExpanded = this.filter && this.isDataFilled;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    isDataFilled() {
      try {
        if (!Array.isArray(this.data)) {
          return this.data != undefined;
        } else if (Array.isArray(this.data)) {
          return Array.isArray(this.data) && this.data.length;
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    localPlaceholder() {
      try {
        if (this.placeholder || !this.isDataFilled) {
          return this.placeholder;
        }
        return "Bitte klicken Sie hier um die Ergebnisse anzuzeigen...";
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  },
};
</script>

<style scoped></style>
