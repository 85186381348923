import gql from 'graphql-tag';

const _props = `
        Id
        HostId
        UserId
        ClientAdvisorChangeRequestStatusId
        CreatedBy
        CreatedAt
        UpdatedBy
        UpdatedAt
    `;

export const ClientAdvisorChangeRequests = {
    Queries: {
        ClientAdvisorChangeRequests: gql`
        query clientAdvisorChangeRequests($hostId: ID, $userId: ID, $clientAdvisorChangeRequestStatusIds: [ID], $offset: Int, $limit: Int) {
            clientAdvisorChangeRequests(hostId: $hostId, userId: $userId, clientAdvisorChangeRequestStatusIds: $clientAdvisorChangeRequestStatusIds, offset: $offset, limit: $limit) {
                ${_props}
            }
        }`,
        ClientAdvisorChangeRequestsCount: gql`
        query clientAdvisorChangeRequestsCount($hostId: ID, $userId: ID, $clientAdvisorChangeRequestStatusIds: [ID]) {
            clientAdvisorChangeRequestsCount(hostId: $hostId, userId: $userId, clientAdvisorChangeRequestStatusIds: $clientAdvisorChangeRequestStatusIds)
        }`,
    },
    Mutations: {
        SaveClientAdvisorChangeRequest: gql`
        mutation saveClientAdvisorChangeRequest($clientAdvisorChangeRequest: ClientAdvisorChangeRequestsInput) {
            saveClientAdvisorChangeRequest(clientAdvisorChangeRequest: $clientAdvisorChangeRequest) {
                IsSuccess
                Message
            }
        }`,
    }
}
