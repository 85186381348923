// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";
// @ts-ignore
import {HostAcquisitionStatus} from "../../../graphql/hostAcquisitionStatus/HostAcquisitionStatus.ts";

export class HostAcquisitionStatusComponent extends BaseComponent {
    async getHostAcquisitionStatus(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hostAcquisitionStatus = await apollo.query({
                query: HostAcquisitionStatus.Queries.HostAcquisitionStatus,
                fetchPolicy: this.fetchPolicy,
            })
                .then(({data}) => data?.hostAcquisitionStatus)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hostAcquisitionStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostAcquisitionStatusById(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const hostAcquisitionStatus = await apollo.query({
                query: HostAcquisitionStatus.Queries.HostAcquisitionStatusById,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    id: id
                }
            })
                .then(({data}) => data?.hostAcquisitionStatusById)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hostAcquisitionStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
