import gql from "graphql-tag";

const _props = `
    Id
    Guid
    ProductContractNumber
    CreatedAt
    CreatedBy
    UpdatedAt
    PdfFilePath
    ProductContractStatusId
    SentAt
    SentBy
    SendNotice
    ConfirmedAt
    ConfirmedBy
    RejectedAt
    RejectedBy
    RejectionNotice
    IsRestock
    Editor
    RevokedAt
    RevokedBy
    RevokationNotice
    Language
    Location
    Signature
    Receiver
    ProductContractTypeId
    ProductContractName
    ProductContractId
    Notice
    CompanyName
    CompanyNameExtra
    CompanyOwner
    CompanyStreet
    CompanyZipCode
    CompanyLocation
    AccountOwner
    BIC
    IBAN
    UStIDNr
    AreTermsIncluded
`;

export const ProductContracts = {
    Queries: {
        ProductContract: gql`
        query productContract($id: ID) {
            productContract(id: $id) {
                ${_props}
            }
        }`,
        ProductContracts: gql`
        query productContracts($hostId: ID, $productId: ID, $userId: ID, $productDraftId: ID, $productContractStatusIds: [ID], $productContractTypeIds: [ID], $offset: Int, $limit: Int) {
            productContracts(hostId: $hostId, productId: $productId, userId: $userId, productDraftId: $productDraftId, productContractStatusIds: $productContractStatusIds, productContractTypeIds: $productContractTypeIds, offset: $offset, limit: $limit) {
                ${_props}
            }
        }`,
        ProductContractByProductContractNumber: gql`
        query productContractByProductContractNumber($productContractNumber: String, $hostId: ID) {
            productContractByProductContractNumber(productContractNumber: $productContractNumber, hostId: $hostId) {
                ${_props}
            }
        }`,
        ProductContractsCount: gql`
        query productContractsCount($hostId: ID, $productId: ID, $userId: ID, $productDraftId: ID, $productContractStatusIds: [ID], $productContractTypeIds: [ID]) {
            productContractsCount(hostId: $hostId, productId: $productId, userId: $userId, productDraftId: $productDraftId, productContractStatusIds: $productContractStatusIds, productContractTypeIds: $productContractTypeIds)
        }`,
        ProductContractNumber: gql`
        query productContractNumber($hostId: ID, $productContractId: ID) {
            productContractNumber(hostId: $hostId, productContractId: $productContractId)
        }`,
    },
    Mutations: {
        SaveProductContract: gql`
        mutation saveProductContract($productContract: ProductContractsInput) {
            saveProductContract(productContract: $productContract) {
                IsSuccess
                Message
                StringData
            }
        }`,
        UpdateProductContract: gql`
        mutation updateProductContract($productContractId: ID, $productContractStatusId: ID, $notice: String, $userId: ID, $email: String) {
            updateProductContract(productContractId: $productContractId, productContractStatusId: $productContractStatusId, notice: $notice, userId: $userId, email: $email) {
                IsSuccess
                Message
            }
        }`,
        UploadProductContract: gql`
        mutation uploadProductContract($productContractId: ID, $userId: ID, $contract: FilesInput, $terms: FilesInput) {
            uploadProductContract(productContractId: $productContractId, userId: $userId, contract: $contract, terms: $terms) {
                IsSuccess
                Message
            }
        }`,
        ReuploadProductContract: gql`
        mutation reuploadProductContract($productContractId: ID) {
            reuploadProductContract(productContractId: $productContractId) {
                IsSuccess
                Message
            }
        }`,
        ReuploadGeneralTermsAndConditions: gql`
        mutation reuploadGeneralTermsAndConditions($productContractId: ID) {
            reuploadGeneralTermsAndConditions(productContractId: $productContractId) {
                IsSuccess
                Message
            }
        }`,
        LoadProductContractHtml: gql`
        mutation loadProductContractHtml($productContractId: ID) {
            loadProductContractHtml(productContractId: $productContractId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        SendProductContractMail: gql`
        mutation sendProductContractMail($productContractId: ID, $includeTerms: Boolean) {
            sendProductContractMail(productContractId: $productContractId, includeTerms: $includeTerms) {
                IsSuccess
                Message
            }
        }`,
        SendProductContractRevokationMail: gql`
        mutation sendProductContractRevokationMail($productContractId: ID) {
            sendProductContractRevokationMail(productContractId: $productContractId) {
                IsSuccess
                Message
            }
        }`,
    },
};
