import gql from "graphql-tag";

const _props = `
    Id
    HostId
    HostAcquisitionStatusId
    Comment
    CreatedBy
    CreatedAt
    Date
    IsDone
`;

export const HostAcquisitionEntries = {
    Mutations: {
        CreateHostAcquisitionEntry: gql`
        mutation createHostAcquisitionEntry($hostAcquisitionEntry: HostAcquisitionEntriesInput) {
            createHostAcquisitionEntry(hostAcquisitionEntry: $hostAcquisitionEntry) {
                IsSuccess
                Message
                JsonData
            }
        }`,
    },
    Queries: {
        HostAcquisitionEntries: gql`
        query hostAcquisitionEntries($hostId: ID, $createdBy: ID, $from: Date, $to: Date, $areNullableDatesIncluded: Boolean, $hostAcquisitionStatusIds: [ID], $isDone: Boolean, $offset: Int, $limit: Int, $order: [[String]]) {
            hostAcquisitionEntries(hostId: $hostId, createdBy: $createdBy, from: $from, to: $to areNullableDatesIncluded: $areNullableDatesIncluded, hostAcquisitionStatusIds: $hostAcquisitionStatusIds, isDone: $isDone, offset: $offset, limit: $limit, order: $order) {
                ${_props}
            }
        }`,
        HostAcquisitionEntriesCount: gql`
        query hostAcquisitionEntriesCount($hostId: ID, $createdBy: ID, $from: Date, $to: Date, $areNullableDatesIncluded: Boolean, $hostAcquisitionStatusIds: [ID], $isDone: Boolean) {
            hostAcquisitionEntriesCount(hostId: $hostId, createdBy: $createdBy, from: $from, to: $to, areNullableDatesIncluded: $areNullableDatesIncluded, hostAcquisitionStatusIds: $hostAcquisitionStatusIds, isDone: $isDone)
        }`
    }
}
