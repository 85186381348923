<template lang="pug">
  header.sticky-top
    #globalhead
      nav.navbar.navbar-expand-lg.navbar-dark
        router-link.navbar-brand(:to="{ name: 'Home' }")
          img(src='https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png' width="25" height="25" alt="tD")
        button.navbar-toggler(type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation")
          span.navbar-toggler-icon
        #mainNav.collapse.navbar-collapse
          ul.navbar-nav
            li.nav-item
              a.nav-link.nav-back(@click="$router.back()")
                font-awesome-icon(:icon="['fas', 'arrow-left']")

            li.nav-item.dropdown
              a#searchDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false') Suche
              .dropdown-menu(aria-labelledby='searchDropdown')
                router-link.dropdown-item(:to="{ name: 'HostsList' }") Gastgeber
                router-link.dropdown-item(:to="{ name: 'ProductsList' }") Produkte

            li.nav-item
              router-link.nav-link(:to="{ name: 'AcquisitionMap' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Sales || p.Id == PermissionsEnum.Admin)") Einkauf
            li.nav-item
              router-link.nav-link(:to="{ name: 'MarketingOverview' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Marketing || p.Id == PermissionsEnum.Admin)") Marketing
            li.nav-item
              router-link.nav-link(:to="{ name: 'OrdersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Service || p.Id == PermissionsEnum.Admin)") Service
            li.nav-item
              router-link.nav-link(:to="{ name: 'MolossCategoriesList' }") Moloss
            li.nav-item
              router-link.nav-link(:to="{ name: 'AdminEvaluations' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Admin)") Admin
            li.nav-item.dropdown
              a#usersDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                | Benutzer
              .dropdown-menu(aria-labelledby='usersDropdown')
                router-link.dropdown-item(:to="{ name: 'ProductManagerUsersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Admin)") Produktmanager
                router-link.dropdown-item(:to="{ name: 'DashboardUsersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Sales || p.Id == PermissionsEnum.Admin)") Dashboard
          .ml-auto.d-flex
            .input-group.mr-3(v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Sales || p.Id == PermissionsEnum.Admin) && users && changeUser")
              select.form-control(v-model="userId")
                option(:value="user.Id" v-for="user in users") {{ user.FirstName }} {{ user.LastName }}
              .input-group-append
                button.button.button-primary.button-tdays(@click="changeUser(userId)" :disabled="!userId || userId == user.Id" :class="{ 'disabled': !userId || userId == user.Id }")
                  font-awesome-icon.mr-2(:icon="['fas', 'arrows-rotate']")
                  span wechseln

            ul.navbar-nav
              li.nav-item(v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Service || p.Id == PermissionsEnum.Admin)")
                a.header-button.txt-white.d-lg-flex.d-none(@click="openCallbacksModal")
                  font-awesome-icon(:icon="['fas', 'phone']")
                  p.badge.badge-danger.rounded-circle {{ notDoneCallbacksCount }}
                a.nav-link.d-flex.d-lg-none(@click="openCallbacksModal") Rückrufe
                  p.badge.badge-danger.rounded-circle.ml-1(v-if="notDoneCallbacksCount") {{ notDoneCallbacksCount }}

              li.nav-item
                a.header-button.txt-white.d-none.d-lg-flex(@click="openNotificationsModal")
                  font-awesome-icon(:icon="['fas', 'bell']")
                  p.badge.badge-danger.rounded-circle(v-if="notDoneNotificationsCount") {{ notDoneNotificationsCount }}
                a.nav-link.d-flex.d-lg-none(@click="openNotificationsModal") Benachrichtigungen
                  p.badge.badge-danger.rounded-circle.ml-1 {{ notDoneNotificationsCount }}

              li.nav-item(v-if="mailbox")
                router-link.header-button.txt-white.d-none.d-lg-flex(:to="{ name: 'MailboxFolderMails', params: { mailboxId: mailbox.Id, mailboxFolderId: MailboxFoldersEnum.Inbox } }")
                  font-awesome-icon(:icon="['fas', 'envelope']")
                  p.badge.badge-danger.rounded-circle(v-if="unreadMailsCount") {{ unreadMailsCount }}
                router-link.nav-link.d-flex.d-lg-none(:to="{ name: 'MailboxFolderMails', params: { mailboxId: mailbox.Id, mailboxFolderId: MailboxFoldersEnum.Inbox } }")
                  p.badge.badge-danger.rounded-circle.ml-1(v-if="unreadMailsCount") {{ unreadMailsCount }}

              li.nav-item.mt-1(v-if="user")
                a#userDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                  | {{ user.FirstName }}
                .dropdown-menu(aria-labelledby='userDropdown')
                  router-link.dropdown-item(:to="{ name: 'UserDetails', params: { userId: user.Id }, query: { isDashboardUser: user.IsDashboardUser, redirectUrl: $router.currentRoute.fullPath } }") Benutzer
                  router-link.dropdown-item(:to="{ name: 'UserProducts', params: { userId: user.Id } }") Produkte
                  router-link.dropdown-item(:to="{ name: 'UserEvaluations', params: { userGuid: user.Guid } }") Auswertungen
                  router-link.dropdown-item(:to="{ name: 'UserContracts', params: { userGuid: user.Guid } }") Verträge
                  router-link.dropdown-item(:to="{ name: 'NotificationsList', params: { userId: user.Id } }") Benachrichtigungen
                  router-link.dropdown-item(:to="{ name: 'AppointmentsList', params: { userId: user.Id } }") Termine
                  a.dropdown-item(@click="logout") abmelden

</template>

<script>
import EventBus from "../../event-bus";
import {PermissionsEnum} from "@/utils/enums/permissions/PermissionsEnum";
import NotificationsModal from "@/views/notifications/NotificationsModal.vue";
import {NotificationTypesEnum} from "@/utils/enums/notificationTypes/NotificationTypesEnum.ts";
import CallbacksModal from "@/views/callbacks/CallbacksModal.vue";
import {NotificationsComponent} from "@/lib/components/notifications/NotificationsComponent.ts";
import {CallbacksComponent} from "@/lib/components/callbacks/CallbacksComponent.ts";
import moment from "moment/moment";
import {MailsComponent} from "@/lib/components/mails/MailsComponent.ts";
import {MailTypesEnum} from "@/utils/enums/mailTypes/MailTypesEnum.ts";
import {MailboxFoldersEnum} from "@/utils/enums/mailboxFolders/MailboxFoldersEnum.ts";
import {BrowserNotificationsComponent} from "@/lib/components/browserNotifications/BrowserNotificationsComponent.ts";
import {
  HostAcquisitionEntriesComponent
} from "@/lib/components/hostAcquisitionEntries/HostAcquisitionEntriesComponent.ts";
import HostAcquisitionEntryModal from "@/views/hostAcquisitionEntries/HostAcquisitionEntryModal.vue";

export default {
  name: "Header",
  props: {
    user: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: false,
    },
    mailbox: {
      type: Object,
      required: false,
    },
    changeUser: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      notificationsCount: 0,
      permissions: this.$session.get("permissions"),
      salesSupportUser: undefined,

      notDoneNotificationsCount: 0,
      unreadMailsCount: 0,
      notDoneCallbacksCount: 0,

      notificationInterval: 1,
      notificationTimespan: 15,
      acquisitionInterval: 1,
      alreadyNotificatedNotificationIds: [],

      PermissionsEnum: PermissionsEnum,
      MailboxFoldersEnum: MailboxFoldersEnum,

      userId: this.user?.Id,
    };
  },
  async mounted() {
    try {
      this.loadNotDoneNotificationsCount();
      this.loadCallbacksCount();
      this.loadMailsCount();

      // INTERVALS
      setInterval(
          this.checkNotifications,
          this.notificationInterval * 60 * 1000
      ); // min * secs * milsecs
      setInterval(
          this.clearupAlreadyNotificatedNotifications,
          this.notificationTimespan * 60 * 1000
      ); // min * secs * milsecs
      setInterval(
          this.loadHostAcquisitionEntries,
          this.acquisitionInterval * 60 * 1000
      ); // min * secs * milsecs

      // EVENT LISTENERS
      EventBus.$on("updateNotDoneNotificationsCount", () => {
        this.loadNotDoneNotificationsCount();
      });
      EventBus.$on(
          "updateCallbacks",
          function () {
            this.loadCallbacksCount();
          }.bind(this)
      );
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadHostAcquisitionEntries() {
      try {
        const hostAcquisitionEntriesComponent = new HostAcquisitionEntriesComponent();
        const hostAcquisitionEntries = await hostAcquisitionEntriesComponent.getHostAcquisitionEntries(this.$apollo, undefined, this.$session.get("userId"), moment().add(15, "minutes").startOf("minute").toDate(), moment().add(15, "minutes").endOf("minute").toDate(), false, undefined, false);
        if (!hostAcquisitionEntries || !Array.isArray(hostAcquisitionEntries)) {
          return;
        }
        for (const hostAcquisitionEntry of hostAcquisitionEntries) {
          this.$modal.show(
              HostAcquisitionEntryModal,
              {
                hostAcquisitionEntry: hostAcquisitionEntry,
              },
          );
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    clearupAlreadyNotificatedNotifications() {
      try {
        this.alreadyNotificatedNotificationIds = [];
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    logout() {
      try {
        this.$session.destroy();
        EventBus.$emit("changeLoginState", false);
        this.$router.push("/login").catch(() => {
        });
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadNotDoneNotificationsCount() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const notDoneNotificationsCount =
            await notificationsComponent.getNotificationsCount(
                this.$apollo,
                this.user?.Id,
                undefined,
                undefined,
                undefined,
                undefined,
                NotificationTypesEnum.ProductManager,
                undefined,
                false,
                undefined,
                moment().add(15, "minutes").toDate()
            );
        if (notDoneNotificationsCount == undefined) {
          return;
        }
        this.notDoneNotificationsCount = notDoneNotificationsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadCallbacksCount() {
      try {
        const callbacksComponent = new CallbacksComponent();
        const callbacksCount = await callbacksComponent.getCallbacksCount(
            this.$apollo,
            false
        );
        if (callbacksCount == undefined) {
          return;
        }
        this.notDoneCallbacksCount = callbacksCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openNotificationsModal() {
      try {
        this.$modal.show(
            NotificationsModal,
            {
              userId: this.user?.Id,
              createdBy: this.user?.Id,
            },
            {
              height: "auto",
              classes: ["rounded-0", "w-auto"],
            }
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openCallbacksModal() {
      try {
        this.$modal.show(
            CallbacksModal,
            {},
            {
              height: "auto",
              classes: ["rounded-0", "w-auto"],
            }
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async checkNotifications() {
      try {
        this.loadNotDoneNotificationsCount();
        const notificationsComponent = new NotificationsComponent();
        const notifications = await notificationsComponent.getNotifications(
            this.$apollo,
            this.user?.Id,
            undefined,
            undefined,
            this.user?.Id,
            undefined,
            NotificationTypesEnum.ProductManager,
            undefined,
            false,
            moment().toDate(),
            moment().add(this.notificationTimespan, "minutes").toDate()
        );
        if (
            !notifications ||
            !Array.isArray(notifications) ||
            !notifications.length
        ) {
          return;
        }
        const browserNotificationsComponent =
            new BrowserNotificationsComponent();
        for (const notification in notifications) {
          if (
              this.alreadyNotificatedNotificationIds.includes(notification.Id)
          ) {
            continue;
          }
          await browserNotificationsComponent.showNotification(
              notification.Title
          );
          this.alreadyNotificatedNotificationIds.push(notification.Id);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadMailsCount() {
      try {
        if (!this.mailbox) {
          return;
        }
        const mailsComponent = new MailsComponent();
        const mailsCount = await mailsComponent.getMailsCount(
            this.$apollo,
            this.mailbox?.Id,
            undefined,
            MailTypesEnum.Inbox,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false
        );
        if (mailsCount == undefined) {
          return;
        }
        this.unreadMailsCount = mailsCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    user() {
      try {
        if (!this.user) {
          return;
        }
        this.loadNotDoneNotificationsCount();
        this.loadCallbacksCount();
        this.loadMailsCount();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.dropdown-menu.show {
  display: block;
  width: 100%;
  border-radius: 0;
  background-color: #333333;
  border: none;
  margin-top: -2px;

  a {
    color: rgba(255, 255, 255, 0.5);
    padding-left: 15px;
    padding-right: 15px;
  }

  a:hover {
    color: #fff;
    background-color: #333333;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #333333;
}
</style>
