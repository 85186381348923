<template lang="pug">
  .notification-details
    .row
      .col-12
        b-card
          b-card-title
            h5 Benachrichtigung
          .row
            .col-12.mt-2
              label.form-label Titel
              input.form-control(type="text" v-model="notification.Title" :disabled="!isNewNotification")
            .col-12.mt-2
              label.form-label Nachricht
              textarea-autosize(v-model="notification.Message" :disabled="!isNewNotification")
            .col-12.mt-2
              label.form-label Datum
              input.form-control(type="datetime-local" :value="notification.NotificationDate | formatInputDateTime" @change="notification.NotificationDate =($event.target.value ? $event.target.value : null);" :disabled="isDisabled || isDone")

    .row.mt-2
      .col-12
        b-card
          b-card-title
            h5 Verlinkungen
          .row
            .col-12
              label.form-label Gastgeber
              search-select(
                :data="hosts"
                :selected-data="host"
                field="host"
                placeholder="Email oder Name..."
                :load-data="loadHosts"
                :selected-data-changed="changeData"
                :is-disabled="isDone || isDisabled"
              )

          .row.mt-2(v-if="notification.HostId")
            .col-12
              label.form-label Produkt
              search-select(
                :data="products"
                :selected-data="product"
                field="product"
                placeholder="Id"
                :load-data="loadProducts"
                :selected-data-changed="changeData"
                :is-disabled="isDone || isDisabled"
              )

          .row.mt-2(v-if="!notification.UserId")
            .col-12
              label.form-label Benutzergruppe
              search-select(
                :data="userGroups"
                :selected-data="userGroup"
                field="userGroup"
                placeholder="Name..."
                :load-data="loadUserGroups"
                :selected-data-changed="changeData"
                :is-disabled="isDone || isDisabled"
              )

          .row.mt-2(v-if="!notification.UserGroupId")
            .col-12
              label.form-label Benutzer
              search-select(
                :data="users"
                :selected-data="user"
                field="user"
                placeholder="Name oder E-Mail..."
                :load-data="loadUsers"
                :selected-data-changed="changeData"
                :is-disabled="isDone || isDisabled"
              )

          .row.mt-3(v-if="notification.Id")
            .col-12
              a.button.button-primary.button-tdays.w-100(:href="notification.RedirectUrl" v-if="!notification.HostId && !notification.ProductId && notification.RedirectUrl") Link öffnen

    .row.mt-2(v-if="notificationTags && Array.isArray(notificationTags) && notificationTags.length")
      .col-12
        b-card
          b-card-title
            h5 Tags
          .row
            .col-12
              .d-flex
                notification-tag(
                  v-for="notificationTag in notificationTags"
                  :notification-tag="notificationTag"
                  :notification-tag-assignments="notificationTagAssignments"
                  :add-or-create-notification-tag-assignment="addOrCreateNotificationTagAssignment"
                  :delete-notification-tag-assignment="deleteNotificationTagAssignment"
                )


    .row.mt-2(v-if="notification.ProductStatusAssignmentId && product")
      .col-12
        h5 Produktstatus
        host-editing-progress-card-product(:product="product")

    .row.mt-2(v-if="notification.Id")
      .col-12
        b-card
          b-card-title
            h5 Historie
          .row
            .col-md-6.col-sm-12
              label.form-label erstellt am
              input.form-control(type="text" :value="notification.CreatedAt | formatDateTime" disabled)
            .col-md-6.col-sm-12
              label.form-label erstellt von
              input.form-control(type="text" :value="`${createdBy.Id ? `${createdBy.FirstName} ${createdBy.LastName}` : ''}`" disabled)
            .col-md-6.col-sm-12
              label.form-label gesehen am
              input.form-control(type="text" :value="notification.SeenAt | formatDateTime" disabled)
            .col-md-6.col-sm-12
              label.form-label gesehen von
              input.form-control(type="text" :value="`${seenBy.Id ? `${seenBy.FirstName} ${seenBy.LastName}` : ''}`" disabled)
            .col-md-6.col-sm-12
              label.form-label erledigt am
              input.form-control(type="text" :value="notification.DoneAt | formatDateTime" disabled)
            .col-md-6.col-sm-12
              label.form-label erledigt von
              input.form-control(type="text" :value="`${doneBy.Id ? `${doneBy.FirstName} ${doneBy.LastName}` : ''}`" disabled)

    .row.mt-2(v-if="!isNewNotification")
      .col-12
        b-card
          b-card-title
            h5 Kommentare
          .row.mt-3
            .col-12
              .input-group
                input.form-control(type="text", v-model="notificationComment.Comment" placeholder="Kommentar" v-on:keyup.enter="createNotificationComment")
                .input-group-append
                  button.button.button-primary.button-tdays(@click="createNotificationComment")
                    font-awesome-icon(:icon="['fas', 'floppy-disk']")
          notification-comment.mt-2(
            v-for="notificationComment in notificationComments"
            :notification-comment="notificationComment"
          )

    .row.mt-3
      .col-md-6.col-sm-12
        button.button.button-primary.button-tbook(
          v-if="!isNewNotification"
          @click="setNotificationAsDone"
          :disabled="isDone"
          :class="{ 'disabled': isDone }"
        ) erledigen
      .col-md-6.col-sm-12.mt-3
        .float-right
          button.button.button-primary.button-tdays(
            v-if="!isDisabled"
            @click="save"
            :disabled="isDone"
            :class="{ 'disabled': isDone }"
          ) speichern

</template>

<script>
import {NotificationTypesEnum} from "@/utils/enums/notificationTypes/NotificationTypesEnum.ts";
import EventBus from "@/event-bus";
import {ProductsComponent} from "@/lib/components/products/ProductsComponent.ts";
import {NotificationCommentsComponent} from "@/lib/components/notificationComments/NotificationCommentsComponent.ts";
import {UsersComponent} from "@/lib/components/users/UsersComponent.ts";
import {NotificationsComponent} from "@/lib/components/notifications/NotificationsComponent.ts";
import NotificationComment from "@/views/notifications/partials/NotificationComment.vue";
import {HostsComponent} from "@/lib/components/hosts/HostsComponent.ts";
import HostEditingProgressCardProduct from "@/views/host/hostEditing/HostEditingProgressCardProduct.vue";
import SearchSelect from "@/views/selects/searchSelect/SearchSelect.vue";
import {UserGroupsComponent} from "@/lib/components/userGroups/UserGroupsComponent.ts";
import {PermissionsEnum} from "@/utils/enums/permissions/PermissionsEnum";
import {NotificationTagsComponent} from "@/lib/components/notificationTags/NotificationTagsComponent.ts";
import NotificationTag from "@/views/notificationTags/NotificationTag.vue";
import {
  NotificationTagAssignmentsComponent
} from "@/lib/components/notificationTagAssignments/NotificationTagAssignmentsComponent.ts";


export default {
  name: "Notification",
  components: {
    NotificationTag,
    SearchSelect,
    HostEditingProgressCardProduct,
    NotificationComment,
  },
  props: {
    notificationId: {
      type: [String, Number],
      required: false,
    },
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
    redirect: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      notification: {
        NotificationTypeId: NotificationTypesEnum.ProductManager,
        UserId: this.$session.get("userId"),
        HostId: this.hostId,
        ProductId: this.productId,
        NotificationDate: this.date,
      },

      notificationTags: [],
      notificationTagAssignments: [],

      host: undefined,
      hosts: [],

      product: undefined,
      products: [],

      userGroup: undefined,
      userGroups: [],
      userGroupUsers: [],

      user: undefined,
      users: [],

      createdBy: {},
      seenBy: {},
      doneBy: {},

      notificationComments: [],
      notificationComment: {},

      isAdmin:
          this.$session
              .get("permissions")
              .find((p) => p.Id == PermissionsEnum.Admin) != undefined,
    };
  },
  async mounted() {
    try {
      if (!this.notificationId && (this.hostId || this.productId)) {
        this.notification.RedirectUrl = window.location.href;
      }
      this.loadNotification();
      this.loadNotificationComments();
      this.loadHost(this.hostId);
      this.loadProduct(this.productId);
      this.loadNotificationTags();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadNotificationTags() {
      try {
        const notificationTagsComponent = new NotificationTagsComponent(undefined, "cache-first");
        const notificationTags = await notificationTagsComponent.getNotificationTags(this.$apollo, true, true);
        if (!notificationTags) {
          return;
        }
        this.notificationTags = notificationTags;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadNotificationTagAssignments() {
      try {
        const notificationTagAssignmentsComponent = new NotificationTagAssignmentsComponent();
        const notificationTagAssignments = await notificationTagAssignmentsComponent.getNotificationTagAssignments(this.$apollo, this.notification?.Id);
        if (!notificationTagAssignments) {
          return;
        }
        this.notificationTagAssignments = notificationTagAssignments;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async addOrCreateNotificationTagAssignment(notificationTagId) {
      try {
        if (!notificationTagId) {
          return;
        }
        const notificationTagAssignment = {
          NotificationTagId: notificationTagId,
          NotificationId: this.notification?.Id,
          CreatedBy: this.$session.get("userId"),
        };
        if (!notificationTagAssignment.NotificationId) {
          return this.notificationTagAssignments.push(notificationTagAssignment);
        }
        const notificationTagAssignmentsComponent = new NotificationTagAssignmentsComponent();
        EventBus.$emit("changeLoadingState", true);
        const createdNotificationTagAssignment = await notificationTagAssignmentsComponent.createNotificationTagAssignment(this.$apollo, notificationTagAssignment);
        EventBus.$emit("changeLoadingState", false);
        if (!createdNotificationTagAssignment) {
          return this.$alert("Der Tag konnte nicht erstellt werden.");
        }
        if (!createdNotificationTagAssignment.IsSuccess && createdNotificationTagAssignment.Message) {
          return this.$alert(createdNotificationTagAssignment.Message);
        }
        this.loadNotificationTagAssignments();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async deleteNotificationTagAssignment(notificationTagAssignment) {
      try {
        if (!notificationTagAssignment) {
          return;
        }
        if (notificationTagAssignment.Id) {
          const notificationTagAssignmentsComponent = new NotificationTagAssignmentsComponent();
          const deletedNotificationTagAssignment = await notificationTagAssignmentsComponent.deleteNotificationTagAssignment(this.$apollo, notificationTagAssignment.Id);
          if (!deletedNotificationTagAssignment) {
            return this.$alert("Das Tag konnte nicht gelöscht werden.");
          }
          if (deletedNotificationTagAssignment.IsSuccess && deletedNotificationTagAssignment.Message) {
            return this.$alert(deletedNotificationTagAssignment.Message);
          }
        }
        this.notificationTagAssignments.splice(this.notificationTagAssignments.indexOf(notificationTagAssignment), 1);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadNotification() {
      try {
        if (!this.notificationId || this.notificationId == "new") {
          return;
        }
        const notificationsComponent = new NotificationsComponent();
        EventBus.$emit("changeLoadingState", true);
        const notification = await notificationsComponent.getNotification(
            this.$apollo,
            this.notificationId
        );
        EventBus.$emit("changeLoadingState", false);
        if (!notification) {
          return;
        }
        this.notification = notification;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async save() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const savedNotification = await this.saveNotification();
        EventBus.$emit("changeLoadingState", false);
        if (!savedNotification) {
          return this.$alert("Benachrichtigung konnte nicht gespeichert werden.");
        }
        if (!savedNotification.IsSuccess && savedNotification.Message) {
          return this.$alert(savedNotification.Message);
        }
        EventBus.$emit("changeLoadingState", true);
        const notificationTagAssignmentsComponent = new NotificationTagAssignmentsComponent();
        for (const notificationTagAssignment of this.notificationTagAssignments) {
          if (notificationTagAssignment.Id) {
            continue;
          }
          const savedNotificationTagAssignment = await notificationTagAssignmentsComponent.createNotificationTagAssignment(this.$apollo, notificationTagAssignment);
          if (!savedNotificationTagAssignment) {
            EventBus.$emit("changeLoadingState", false);
            return this.$alert("Der Tag konnte nicht gespeichert werden.");
          }
          if (!savedNotificationTagAssignment.IsSuccess && savedNotificationTagAssignment.Message) {
            EventBus.$emit("changeLoadingState", false);
            return this.$alert(savedNotificationTagAssignment.Message);
          }
        }
        EventBus.$emit("changeLoadingState", false);
        EventBus.$emit("updateNotifications");
        EventBus.$emit("updateNotDoneNotificationsCount");
        if (this.redirect) {
          this.redirect();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveNotification() {
      try {
        if (this.notification.Id) {
          this.notification.UpdatedBy = this.$session.get("userId");
        } else {
          this.notification.CreatedBy = this.$session.get("userId");
          this.notification.SourceUserId = this.notification?.UserId;
          this.notification.SourceUserGroupId = this.notification?.UserGroupId;
          this.notification.HostId = this.host?.Id;
          this.notification.ProductId = this.product?.Id;
          this.notification.RedirectUrl = window.location.href;
        }
        const savedNotification = await this._saveNotification(
            this.notification
        );
        if (!savedNotification) {
          return {
            IsSuccess: false,
            Message: "Die Benachrichtigung konnte nicht gespeichert werden.",
          };
        }
        return savedNotification;
      } catch (e) {
        console.error(e);
        return {
          IsSuccess: false,
          Message: e.message,
        };
      }
    },
    async setNotificationAsDone() {
      try {
        const confirmed = await this.$confirm(
            "Sind Sie sicher, dass Sie die Benachrichtigung erledigen möchten?"
        )
            .then()
            .catch((e) => e);
        if (!confirmed) {
          return;
        }
        this.notification.IsDone = true;
        this.notification.DoneAt = new Date();
        this.notification.DoneBy = this.$session.get("userId");
        this.notification.UpdatedBy = this.$session.get("userId");
        EventBus.$emit("changeLoadingState", true);
        const savedNotification = await this._saveNotification(
            this.notification
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedNotification) {
          return this.$alert(
              "Die Benachrichtigung konnte nicht erledigt werden."
          );
        }
        if (savedNotification.Message) {
          if (savedNotification.IsSuccess) {
            this.$swal(savedNotification.Message);
          } else {
            this.$alert(savedNotification.Message);
          }
        }
        if (savedNotification.IsSuccess) {
          EventBus.$emit("updateNotifications");
          this.$emit("close");
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async setNotificationAsSeen() {
      try {
        if (!this.notification.Id || this.notification.IsSeen) {
          return;
        }
        this.notification.IsSeen = true;
        this.notification.SeenAt = new Date();
        this.notification.SeenBy = this.$session.get("userId");
        this.notification.UpdatedBy = this.$session.get("userId");
        const savedNotification = await this._saveNotification(
            this.notification
        );
        if (!savedNotification) {
          return this.$alert(
              "Die Benachrichtigung konnte nicht gelesen werden."
          );
        }
        if (!savedNotification.IsSuccess) {
          return this.$alert(savedNotification.Message);
        }
        this.loadSeenBy();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _saveNotification(notification) {
      try {
        if (!notification) {
          return {
            IsSuccess: false,
            Message: "Keine Benachrichtigung gefunden.",
          };
        }
        const notificationsComponent = new NotificationsComponent();
        const savedNotification = await notificationsComponent.saveNotification(
            this.$apollo,
            notification
        );
        if (!savedNotification) {
          return {
            IsSuccess: false,
            Message: "Die Benachrichtigung konnte nicht gespeichert werden.",
          };
        }
        return savedNotification;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async loadNotificationComments() {
      try {
        const notificationCommentsComponent =
            new NotificationCommentsComponent();
        const notificationComments =
            await notificationCommentsComponent.getNotificationComments(
                this.$apollo,
                this.notificationId
            );
        if (!notificationComments) {
          return;
        }
        this.notificationComments = notificationComments;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async createNotificationComment() {
      try {
        if (!this.notificationComment.Comment.trim()) {
          return this.$alert("Bitte geben Sie zuerst ein Kommentar an.");
        }
        const confirmed = await this.$confirm(
            "Sind Sie sicher, dass Sie das Kommentar speichern wollen?"
        )
            .then()
            .catch((e) => e);
        if (!confirmed) {
          return;
        }
        this.notificationComment.CreatedBy = this.$session.get("userId");
        this.notificationComment.NotificationId = this.notification.Id;
        const notificationCommentsComponent =
            new NotificationCommentsComponent();
        EventBus.$emit("changeLoadingState", true);
        const createdNotificationComment =
            await notificationCommentsComponent.createNotificationComment(
                this.$apollo,
                this.notificationComment
            );
        EventBus.$emit("changeLoadingState", false);
        if (!createdNotificationComment) {
          return this.$alert(
              "Das Benachrichitigungskommentar konnte nicht gespeichert werden."
          );
        }
        if (!createdNotificationComment.IsSuccess) {
          return this.$alert(createdNotificationComment.Message);
        }
        this.notificationComment = {};
        this.loadNotificationComments();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHost(hostId) {
      try {
        if (!hostId) {
          return;
        }
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(this.$apollo, hostId);
        if (!host) {
          return;
        }
        host.type = "host";
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct(productId) {
      try {
        if (!productId) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
            this.$apollo,
            productId
        );
        if (!product) {
          return;
        }
        product.type = "product";
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadCreatedBy() {
      try {
        if (!this.notification.CreatedBy) {
          return;
        }
        const createdBy = await this.getUser(this.notification.CreatedBy);
        if (!createdBy) {
          return;
        }
        this.createdBy = createdBy;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadSeenBy() {
      try {
        if (!this.notification.SeenBy) {
          return;
        }
        const seenBy = await this.getUser(this.notification.SeenBy);
        if (!seenBy) {
          return;
        }
        this.seenBy = seenBy;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadDoneBy() {
      try {
        if (!this.notification.DoneBy) {
          return;
        }
        const doneBy = await this.getUser(this.notification.DoneBy);
        if (!doneBy) {
          return;
        }
        this.doneBy = doneBy;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async getUser(userId) {
      try {
        if (!userId) {
          return undefined;
        }
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(this.$apollo, userId);
        if (!user) {
          return undefined;
        }
        return user;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async loadHosts(filter) {
      try {
        if (!filter || filter.length <= 3) {
          return;
        }
        const hostsComponent = new HostsComponent();
        EventBus.$emit("changeLoadingState", true);
        const hosts = await hostsComponent.getHosts(
            this.$apollo,
            undefined,
            filter,
            undefined,
            undefined,
            0,
            25
        );
        EventBus.$emit("changeLoadingState", false);
        if (!hosts) {
          return;
        }
        hosts.forEach((h) => {
          h.type = "host";
        });
        this.hosts = hosts;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProducts() {
      try {
        if (!this.notification.HostId) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const products = await productsComponent.getProducts(
            this.$apollo,
            undefined,
            this.notification.HostId
        );
        if (!products) {
          return;
        }
        products.forEach((p) => {
          p.type = "product";
        });
        this.products = products;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    changeData(field, data) {
      try {
        this[field] = data;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUserGroups(filter) {
      try {
        if (this.notification.UserId) {
          return;
        }
        const userGroupsComponent = new UserGroupsComponent();
        const userGroups = await userGroupsComponent.getUserGroups(
            this.$apollo,
            filter,
            0,
            25
        );
        if (!userGroups) {
          return;
        }
        userGroups.forEach((ug) => {
          ug.type = "userGroup";
        });
        this.userGroups = userGroups;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUserGroup() {
      try {
        if (!this.notification.UserGroupId) {
          return;
        }
        const userGroupsComponent = new UserGroupsComponent();
        const userGroup = await userGroupsComponent.getUserGroup(
            this.$apollo,
            this.notification.UserGroupId
        );
        if (!userGroup) {
          return;
        }
        userGroup.type = "userGroup";
        this.userGroup = userGroup;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUsers(filter) {
      try {
        const usersComponent = new UsersComponent();
        const users = await usersComponent.getProductManagerUsers(
            this.$apollo,
            filter
        );
        if (!users) {
          return;
        }
        users.forEach((u) => {
          u.type = "user";
        });
        this.users = users;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUser() {
      try {
        if (!this.notification.UserId) {
          return;
        }
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
            this.$apollo,
            this.notification.UserId
        );
        if (!user) {
          return;
        }
        user.type = "user";
        this.user = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    notification() {
      try {
        if (!this.notification) {
          return;
        }
        this.setNotificationAsSeen();
        this.loadHost(this.notification.HostId);
        this.loadProduct(this.notification.ProductId);
        this.loadUser();
        this.loadUserGroup();
        this.loadCreatedBy();
        this.loadSeenBy();
        this.loadDoneBy();
        this.loadNotificationTagAssignments();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    host() {
      try {
        this.notification.HostId = this.host?.Id;
        if (!this.host?.Id) {
          this.product = undefined;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async "notification.HostId"() {
      try {
        this.loadProducts();
      } catch (e) {
        console.error(e);
      }
    },
    product() {
      try {
        this.notification.ProductId = this.product?.Id;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    userGroup() {
      try {
        this.notification.UserGroupId = this.userGroup?.Id;
        if (this.userGroup?.Id) {
          this.user = undefined;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    user() {
      try {
        this.notification.UserId = this.user?.Id;
        if (this.user?.Id) {
          this.UserGroup = undefined;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    isNewNotification() {
      try {
        return !this.notification?.Id;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    isDisabled() {
      try {
        if (!this.notification?.Id) {
          return false;
        }
        if (this.notification.UserId == this.$session.get("userId")) {
          return false;
        }
        if (this.isAdmin) {
          return false;
        }
        if (
            this.userGroupUsers.find(
                (userGroupUser) =>
                    userGroupUser.UserId == this.$session.get("userId")
            )
        ) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    isDone() {
      try {
        if (this.notification?.IsDone && this.notification?.DoneAt) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
