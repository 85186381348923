<template lang="pug">
tr
  td(v-if="showHost")
    router-link(
      v-if="host"
      :to="{ name: 'HostDetails', params: { hostId: host.Id } }"
    ) {{ host.Name }}

  td
    span(v-if="user") {{ user.FirstName }} {{ user.LastName }}

  td
    span(v-if="clientAdvisorChangeRequest.ClientAdvisorChangeRequestStatusId == ClientAdvisorChangeRequestStatusEnum.Requested") angefragt
    span(v-else-if="clientAdvisorChangeRequest.ClientAdvisorChangeRequestStatusId == ClientAdvisorChangeRequestStatusEnum.Accepted") angenommen
    span(v-else-if="clientAdvisorChangeRequest.ClientAdvisorChangeRequestStatusId == ClientAdvisorChangeRequestStatusEnum.Rejected") abgelehnt

  td {{ clientAdvisorChangeRequest.CreatedAt | formatDateTime }}
  td
    span(v-if="creator") {{ creator.FirstName }} {{ creator.LastName }}
  td {{ clientAdvisorChangeRequest.UpdatedAt | formatDateTime }}
  td
    span(v-if="updator") {{ updator.FirstName }} {{ updator.LastName }}
  td(v-if="isEdit")
    .d-flex
      button.button.button-primary.button-tdays.mr-2(@click="saveClientAdvisorChangeRequest(ClientAdvisorChangeRequestStatusEnum.Accepted)")
        font-awesome-icon.mr-2(:icon="['fas', 'check']")
        | akzeptieren
      button.button.button-primary.button-tpics(@click="saveClientAdvisorChangeRequest(ClientAdvisorChangeRequestStatusEnum.Rejected)")
        font-awesome-icon.mr-2(:icon="['fas', 'xmark']")
        | ablehnen

</template>

<script>
import {UsersComponent} from "@/lib/components/users/UsersComponent.ts";
import {HostsComponent} from "@/lib/components/hosts/HostsComponent.ts";
import {
  ClientAdvisorChangeRequestStatusEnum
} from "@/utils/enums/clientAdvisorChangeRequestStatus/ClientAdvisorChangeRequestStatusEnum.ts";
import {
  ClientAdvisorChangeRequestsComponent
} from "@/lib/components/clientAdvisorChangeRequests/ClientAdvisorChangeRequestsComponent.ts";
import EventBus from "@/event-bus";

export default {
  name: "ClientAdvisorChangeRequestsTableRow",
  props: {
    clientAdvisorChangeRequest: {
      type: Object,
      required: true,
    },
    showHost: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      host: undefined,
      user: undefined,
      creator: undefined,
      updator: undefined,

      ClientAdvisorChangeRequestStatusEnum: ClientAdvisorChangeRequestStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadUser();
      this.loadCreator();
      this.loadUpdator();
      if (this.showHost) {
        this.loadHost();
      }
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadHost() {
      try {
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(this.$apollo, this.clientAdvisorChangeRequest.HostId);
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUser() {
      try {
        this.user = await this.getUser(this.clientAdvisorChangeRequest.UserId);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadCreator() {
      try {
        this.creator = await this.getUser(this.clientAdvisorChangeRequest.CreatedBy);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUpdator() {
      try {
        this.updator = await this.getUser(this.clientAdvisorChangeRequest.UpdatedBy);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async getUser(userId) {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(this.$apollo, userId);
        return user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveClientAdvisorChangeRequest(clientAdvisorChangeRequestStatusId) {
      try {
        const confirmed = await this.$confirm("Sind Sie sicher, dass Sie die Anfrage speichern möchten?").then().catch((e) => e);
        if (!confirmed) {
          return;
        }
        this.clientAdvisorChangeRequest.ClientAdvisorChangeRequestStatusId = clientAdvisorChangeRequestStatusId;
        this.clientAdvisorChangeRequest.UpdatedBy = this.$session.get("userId");
        const clientAdvisorChangeRequestsComponent = new ClientAdvisorChangeRequestsComponent();
        const savedClientAdvisorChangeRequest = await clientAdvisorChangeRequestsComponent.saveClientAdvisorChangeRequest(this.$apollo, this.clientAdvisorChangeRequest);
        if (!savedClientAdvisorChangeRequest) {
          return this.$alert("Die Anfrage konnte nicht gespeichert werden.");
        }
        if (savedClientAdvisorChangeRequest.Message) {
          savedClientAdvisorChangeRequest.IsSuccess ? this.$swal(savedClientAdvisorChangeRequest.Message) : this.$alert(savedClientAdvisorChangeRequest.Message);
        }
        if (savedClientAdvisorChangeRequest.IsSuccess) {
          EventBus.$emit("updateClientAdvisorChangeRequests");
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  }
};
</script>

<style scoped>

</style>
