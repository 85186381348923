<template lang="pug">
.host-acquisition-entry-modal.p-5
  template(v-if="host")
    .row
      .col-12
        router-link(:to="{ name: 'HostDetails', params: { hostId: host.Id } }" target="_blank")
          h5.font-weight-bold {{ host.Name }}
    .row.mt-2
      .col-12
        span Ansprechpartner: {{ host.Ansprechpartner }}
    .row.mt-2
      .col-12
        span Telefon:&nbsp;
        a(:href="'tel:' + host.TELEFON1") {{ host.TELEFON1 }}

  .row.mt-2(v-if="hostAcquisitionStatus")
    .col-12
      span.font-weight-bold {{ hostAcquisitionStatus.Name }}
      span :&nbsp;{{ hostAcquisitionEntry.Date | formatDateTime }}
  .row.mt-2
    .col-12
      span {{ hostAcquisitionEntry.Comment }}

</template>

<script>
import {HostAcquisitionStatusComponent} from "@/lib/components/hostAcquisitionStatus/HostAcquisitionStatusComponent.ts";
import {HostsComponent} from "@/lib/components/hosts/HostsComponent.ts";

export default {
  name: "HostAcquisitionEntryModal",
  props: {
    hostAcquisitionEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      host: undefined,
      hostAcquisitionStatus: undefined,
    };
  },
  async mounted() {
    try {
      this.loadHost();
      this.loadHostAcquisitionStatus();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadHost() {
      try {
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(this.$apollo, this.hostAcquisitionEntry?.HostId);
        if (!host) {
          return;
        }
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHostAcquisitionStatus() {
      try {
        const hostAcquisitionStatusComponent = new HostAcquisitionStatusComponent();
        const hostAcquisitionStatus = await hostAcquisitionStatusComponent.getHostAcquisitionStatusById(this.$apollo, this.hostAcquisitionEntry.HostAcquisitionStatusId);
        if (!hostAcquisitionStatus) {
          return;
        }
        this.hostAcquisitionStatus = hostAcquisitionStatus;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    }
  },
};
</script>

<style scoped>

</style>
