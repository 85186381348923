<template lang="pug">
  .leaflet-map
    i.fa-solid.fa-location-dot
    l-map(
      :style="'height:' + localHeight + ';width:100%;'"
      :zoom="zoom"
      :center="center"
      @click="setMarker"
    )
      l-tile-layer(:url='url' :attribution='attribution')
      l-marker(
        v-for="marker in markers"
        :lat-lng="latLng(marker.lat, marker.lng)"
        :name="marker.name"
      )
        l-tooltip(
          :content="marker.name"
          :options="{ interactive: true, permanent: false }"
        )
      l-marker(
        v-for="marker in highlightedMarkers"
        v-if="marker.lat && marker.lng"
        :lat-lng="latLng(marker.lat, marker.lng)"
        :name="marker.name"
        :icon="marker.icon"
      )
        l-tooltip(
          :content="marker.name"
          :options="{ interactive: true, permanent: true }"
        )
      l-circle(
        ref="circle"
        v-if="circle"
        :lat-lng="circle.center"
        :radius="circle.radius"
        @ready="circleReady"
      )
      l-circle(
        v-if="circles && Array.isArray"
        v-for="_circle in circles"
        :lat-lng="_circle.center"
        :radius="_circle.radius"
        :opacity="0.3"
        :fill-opacity="0.3"
        :color="_circle.color"
        :fill-color="_circle.color"
      )
        l-tooltip(
          v-if="_circle.name"
          :content="_circle.name"
          :options="{ interactive: true, permanent: false }"
        )


</template>

<script>
import {latLng} from "leaflet";
import {
  LMap,
  LMarker,
  LTileLayer,
  LCircle,
  LTooltip,
} from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: "LeafletMap",
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LCircle,
    LTooltip,
  },
  props: {
    lat: {
      type: [Number, String],
      required: false,
    },
    lng: {
      type: [Number, String],
      required: false,
    },
    zoom: {
      type: Number,
      required: false,
      default: 6,
    },
    markers: {
      type: Array,
      required: false,
    },
    highlightedMarkers: {
      type: Array,
      required: false,
    },
    height: {
      type: [String, Number],
      required: false,
      default: "300px",
    },
    radius: {
      type: Number,
      required: false,
      default: 20000,
    },
    changeGeoData: {
      type: Function,
      required: false,
    },
    circles: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      latitude: this.lat,
      longitude: this.lng,
      circle: undefined,
      fontAwesomeIcon: L.divIcon({
        html: '<i className="fa-solid fa-location-dot"></i>',
        iconSize: [20, 20],
      }),

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      latLng: latLng,
    };
  },
  methods: {
    setMarker(evt) {
      try {
        if (!evt) {
          return;
        }
        this.latitude = evt.latlng.lat;
        this.longitude = evt.latlng.lng;
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    circleReady(evt) {
      try {
        if (!this.changeGeoData) {
          return;
        }
        this.changeGeoData(evt._latlng.lat, evt._latlng.lng);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    setCircle() {
      try {
        if (!this.changeGeoData) {
          return;
        }
        if (!this.isCircleValid) {
          return;
        }
        this.circle = {
          center: latLng(this.latitude, this.longitude),
          radius: parseInt(this.radius),
        };
        this.changeGeoData(this.latitude, this.longitude);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    center() {
      try {
        if (!this.latitude || !this.longitude) {
          return undefined; //[51, 10.7];
        }
        return [this.latitude, this.longitude];
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    localHeight() {
      try {
        if (typeof this.height === "number") {
          return `${this.height}px`;
        } else if (typeof this.height === "string") {
          if (!this.height.includes("px")) {
            return `${this.height}px`;
          }
          return this.height;
        }
      } catch (e) {
        console.error(e);
        return "300px";
      }
    },
    isCircleValid() {
      try {
        return (this.latitude != undefined && this.longitude != undefined && this.radius != undefined);
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  watch: {
    lat() {
      try {
        this.latitude = parseFloat(this.lat);
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    lng() {
      try {
        this.longitude = parseFloat(this.lng);
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    radius() {
      try {
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    circle() {
      try {
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async longitude() {
      try {
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async latitude() {
      try {
        this.setCircle();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped>
</style>
