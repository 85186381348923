<template lang="pug">
  .badge.rounded-0.p-2(
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    :class="{ 'cursor-pointer': !isDisabled }"
    :style="`${backgroundColor}${color}`"
    @click="handleClick"
  )
    | {{ notificationTag.Name ? notificationTag.Name : 'neuer Tag' }}

</template>

<script>
export default {
  name: "NotificationTag",
  props: {
    notificationTag: {
      type: Object,
      required: true,
    },
    notificationTagAssignments: {
      type: Array,
      required: false,
    },
    addOrCreateNotificationTagAssignment: {
      type: Function,
      required: false,
    },
    deleteNotificationTagAssignment: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    setHover(isHover) {
      try {
        if (this.isDisabled) {
          return;
        }
        this.isHover = isHover;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async handleClick() {
      try {
        if (this.isDisabled) {
          return;
        }
        if (this.notificationTagAssignment) {
          this.deleteNotificationTagAssignment(this.notificationTagAssignment);
        } else {
          this.addOrCreateNotificationTagAssignment(this.notificationTag?.Id);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getColor(hex) {
      try {
        if (!hex) {
          return undefined;
        }
        const rgb = this.hexToRgb(hex);
        if (rgb["r"] * 0.299 + rgb["g"] * 0.587 + rgb["b"] * 0.114 > 200) {
          return "#000000";
        } else {
          return "#ffffff";
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    hexToRgb(hex) {
      try {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
            : null;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  computed: {
    isEdit() {
      try {
        return this.addOrCreateNotificationTagAssignment && this.deleteNotificationTagAssignment;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    notificationTagAssignment() {
      try {
        return this.notificationTagAssignments?.find((notificationTagAssignment) => notificationTagAssignment.NotificationTagId == this.notificationTag.Id);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    backgroundColor() {
      try {
        if (this.isDisabled && this.notificationTag.Color) {
          return `background-color:${this.notificationTag.Color};`;
        }
        if (this.isHover || this.notificationTagAssignment) {
          return `background-color:${this.notificationTag.Color};`;
        }
        return "background-color:#777777;";
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    color() {
      try {
        if (this.isDisabled && this.notificationTag.Color) {
          return `color:${this.getColor(this.notificationTag.Color)};`;
        }
        if (this.isHover || this.notificationTagAssignment) {
          return `color:${this.getColor(this.notificationTag.Color)};`;
        }
        return "color:#ffffff";
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    isDisabled() {
      try {
        if (!this.isEdit) {
          return true;
        }
        return !this.addOrCreateNotificationTagAssignment || !this.deleteNotificationTagAssignment;
      } catch (e) {
        console.error(e);
        return true;
      }
    }
  },
};
</script>

<style scoped>

</style>
