<template lang="pug">
  nav
    ul.pagination.justify-content-center
      li.page-item
        button.page-link(@click="reload(0 * limit)"  :disabled="overallCount == 0 || currentPage == 1" :class="{ 'disabled': overallCount == 0 || currentPage == 1 }") &laquo;
      li.page-item
        button.page-link(@click="reload((currentPage - 2) * limit)"  :disabled="overallCount == 0 || currentPage - 1 == 0" :class="{ 'disabled': overallCount == 0 || currentPage - 1 == 0 }") &lsaquo;
      li.page-item(v-for="page in pages")
        button.page-link(@click="reload((page - 1) * limit)" :disabled="overallCount == 0 || currentPage == page" :class="{ 'disabled': overallCount == 0 || currentPage == page }") {{ page }}
      li.page-item
        button.page-link(@click="reload((currentPage) * limit)" :disabled="overallCount == 0 || currentPage == maxPages" :class="{ 'disabled': overallCount == 0 || currentPage == maxPages }") &rsaquo;
      li.page-item
        button.page-link(@click="reload((maxPages - 1) * limit)" :disabled="overallCount == 0 || currentPage == maxPages" :class="{ 'disabled': overallCount == 0 || currentPage == maxPages }") &raquo;

</template>

<script>
import SessionsComponent from "@/lib/components/sessions/SessionsComponent";

export default {
  name: "pagination",
  props: {
    overallCount: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    load: {
      type: Function,
      required: false,
    },
    queryOffsetName: {
      type: String,
      required: false,
      default: "offset",
    },
  },
  methods: {
    reload(offset) {
      try {
        if (this.load) {
          return this.load(offset);
        } else {
          const query = {};
          if (this.queryOffsetName) {
            query[this.queryOffsetName] = offset;
          } else {
            query.offset = offset;
          }
          const sessionsComponent = new SessionsComponent();
          sessionsComponent.changeQueryState(this, {
            ...this.$route?.query,
            ...query,
          });
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    currentPage() {
      try {
        if (!this.offset || !this.limit) {
          return 1;
        }
        return this.offset / this.limit + 1;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    pages() {
      try {
        const _pages = [];
        if (this.currentPage - 2 > 0) {
          _pages.push(this.currentPage - 2);
        }
        if (this.currentPage - 1 > 0) {
          _pages.push(this.currentPage - 1);
        }
        if (this.currentPage) {
          _pages.push(this.currentPage);
        }
        if (this.currentPage + 1 <= this.maxPages) {
          _pages.push(this.currentPage + 1);
        }
        if (this.currentPage + 2 <= this.maxPages) {
          _pages.push(this.currentPage + 2);
        }
        return _pages;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    maxPages() {
      try {
        if (!this.overallCount || !this.limit) {
          return [];
        }
        const maxPages = Math.ceil(this.overallCount / this.limit);
        if (maxPages == undefined) {
          return undefined;
        }
        return maxPages;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
