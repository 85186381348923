// @ts-ignore
import {Hosts} from "../../../graphql/hosts/Hosts.ts";
// @ts-ignore
import {BaseComponent} from "../BaseComponent.ts";

export class HostsComponent extends BaseComponent {
    async saveHost(apollo, host, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!host) {
                return {
                    IsSuccess: false,
                    Message: "Kein Gastgeber gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            if (host.EMAIL1) {
                host.EMAIL1 = host.EMAIL1?.trim()?.toLowerCase();
            }
            if (host.EMAIL2) {
                host.EMAIL2 = host.EMAIL2?.trim()?.toLowerCase();
            }
            host.Match1 = host.Name;
            const savedHost = await apollo.mutate({
                mutation: Hosts.Mutations.SaveHost,
                fetchPolicy: "no-cache",
                variables: {
                    host: host,
                    userId: userId,
                },
            })
                .then(({data}) => data?.saveHost)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedHost) {
                return {
                    IsSuccess: false,
                    Message: "Der Gastgeber konnte nicht gespeichert werden.",
                };
            }
            return savedHost;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getAcquisitionHosts(apollo, hostAcquisitionStatusIds, createdBy = undefined, from = undefined, to = undefined, areNullableDatesIncluded = undefined, isDone = undefined, offset = undefined, limit = undefined, order = undefined) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostAcquisitionStatusIds || !Array.isArray(hostAcquisitionStatusIds)) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.AcquisitionHosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostAcquisitionStatusIds: hostAcquisitionStatusIds,
                        createdBy: createdBy,
                        from: from,
                        to: to,
                        areNullableDatesIncluded: areNullableDatesIncluded,
                        isDone: isDone,
                        offset: offset,
                        limit: limit,
                        order: order,
                    },
                })
                .then(({data}) => data?.acquisitionHosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getAcquisitionHostsCount(apollo, hostAcquisitionStatusIds, createdBy = undefined, date = undefined, areNullableDatesIncluded = undefined, isDone = undefined) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostAcquisitionStatusIds || !Array.isArray(hostAcquisitionStatusIds)) {
                return undefined;
            }
            const hostsCount = await apollo
                .query({
                    query: Hosts.Queries.AcquisitionHostsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostAcquisitionStatusIds: hostAcquisitionStatusIds,
                        createdBy: createdBy,
                        date: date,
                        areNullableDatesIncluded: areNullableDatesIncluded,
                        isDone: isDone,
                    },
                })
                .then(({data}) => data?.acquisitionHostsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hostsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHosts(apollo, hostPoolId = undefined, filter = undefined, hostStatusIds = undefined, cols = undefined, offset = undefined, limit = undefined, order = undefined) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.Hosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostPoolId: hostPoolId,
                        filter: filter,
                        hostStatusIds: hostStatusIds,
                        cols: cols,
                        offset: offset,
                        limit: limit,
                        order: order,
                    },
                })
                .then(({data}) => data?.hosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsCount(apollo, hostPoolId = undefined, filter = undefined, hostStatusIds = undefined, cols = undefined) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.HostsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostPoolId: hostPoolId,
                        filter: filter,
                        hostStatusIds: hostStatusIds,
                        cols: cols,
                    },
                })
                .then(({data}) => data?.hostsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHost(apollo, hostId, hostStatusId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId) {
                return undefined;
            }
            const host = await apollo
                .query({
                    query: Hosts.Queries.Host,
                    fetchPolicy: this.fetchPolicy,
                    variables: {
                        id: hostId,
                        hostStatusId: hostStatusId,
                    },
                })
                .then(({data}) => data?.host)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!host) {
                return undefined;
            }
            return host;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsByProductStatusIds(apollo, productStatusIds, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productStatusIds) {
                return undefined;
            }
            const hosts = await apollo.query({
                query: Hosts.Queries.HostsByProductStatusIds,
                fetchPolicy: "no-cache",
                variables: {
                    productStatusIds: productStatusIds,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.hostsByProductStatusIds)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsByProductContracts(apollo, productContractId, productContractStatusIds, userIds, from, to) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productContractId && (!productContractStatusIds || !Array.isArray(productContractStatusIds)) && (!userIds || !Array.isArray(userIds)) && !from && !to) {
                return undefined
            }
            const hosts = await apollo.query({
                query: Hosts.Queries.HostsByProductContracts,
                fetchPolicy: this.fetchPolicy,
                variables: {
                    productContractId: productContractId,
                    productContractStatusIds: productContractStatusIds,
                    userIds: userIds,
                    from: from,
                    to: to,
                }
            })
                .then(({data}) => data?.hostsByProductContracts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
