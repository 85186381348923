<template lang="pug">
  .host-search-select-list-results-list-item
    //font-awesome-icon.mr-1(:icon="['fas', 'house']")
    .d-flex.align-items-center
      host-status-icon(:host-status-id="host.HostStatusId")
      span.ml-1 {{ host.Id }}: {{ host.Name }}
    font-awesome-icon.mr-2(:icon="['fas', 'location-dot']")
    span {{ host.PAdresse4 }}, {{ host.PPLZ }} {{ host.PORT }} {{ host.PLAND }}
    template(v-if="host.EMAIL1")
      br
      font-awesome-icon.mr-2(:icon="['fas', 'at']")
      span {{ host.EMAIL1 }}

</template>

<script>
import {HostStatusEnum} from "@/utils/enums/hostStatus/HostStatusEnum.ts";
import HostStatusIcon from "@/views/hostStatus/HostStatusIcon.vue";

export default {
  name: "HostSearchSelectListResultsListItem",
  components: {HostStatusIcon},
  props: {
    host: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      HostStatusEnum: HostStatusEnum,
    };
  },
};
</script>

<style scoped>
.red {
  color: red;
}
.green {
  color: green;
}
.gray {
  color: gray;
}
</style>
